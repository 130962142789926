// extracted by mini-css-extract-plugin
export var ArtistDescription = "Kase2-module--ArtistDescription--l5Cqt";
export var ArtistInfos = "Kase2-module--ArtistInfos--WeE5W";
export var ButtonWrapper = "Kase2-module--ButtonWrapper --wziEC";
export var CardWrapper = "Kase2-module--CardWrapper--ZXnUx";
export var CarrouselWrapper2 = "Kase2-module--CarrouselWrapper2--bz6TY";
export var Citations = "Kase2-module--Citations--uSb1h";
export var DescriptionWrapper = "Kase2-module--DescriptionWrapper--sGWE+";
export var ImageWrapper = "Kase2-module--ImageWrapper--7UljB";
export var LinkWrapper = "Kase2-module--LinkWrapper--5vu1J";
export var MobileProtrait = "Kase2-module--MobileProtrait--5byWv";
export var More = "Kase2-module--More--riNnw";
export var MoreButton = "Kase2-module--MoreButton--gUCi5";
export var NameWrapper = "Kase2-module--NameWrapper--yLPeL";
export var PdpWrapper = "Kase2-module--PdpWrapper--2Iuyu";
export var PhotosWrapper = "Kase2-module--PhotosWrapper--p80sN";
export var ProfilWrapper = "Kase2-module--ProfilWrapper--6oYOF";
export var TitleWrapper = "Kase2-module--TitleWrapper--BbHqY";
export var Wrapper = "Kase2-module--Wrapper--SZvvi";